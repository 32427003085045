<template>
  <div class="xjbl">
    <div v-if="!isDomainAllowed">
      <ul class="list clearfix">
        <li class="item" v-for="(item, i) in binglpingpai" :key="i" @change="rdiogaib">
          <el-radio :label="i + 1" v-model="checked1" @change="onChange(i)" :disabled="disRadio"
            ><img :src="item.logo"
          /></el-radio>
          <div class="text secaitxt">{{ item.info }}</div>
        </li>
      </ul>
      <!-- <ul class="list1">
        <li class="item1">
          <span class="title">当前产品</span>
          <span class="content">{{ currentProduct.product }}</span>
        </li>
        <li class="item1">
          <span class="title">版本特点</span>
          <span class="content">{{ currentProduct.trait }}</span>
        </li>
        <li class="item1">
          <span class="title">矫治器</span>
          <span class="content">{{ currentProduct.appliance }}</span>
        </li>
        <li class="item1">
          <span class="title">服务期</span>
          <span class="content">{{ currentProduct.period }}</span>
        </li>
        <li class="item1">
          <span class="title">适用病例</span>
          <span class="content">{{ currentProduct.apply }}</span>
        </li>
        <li class="item1">
          <span class="title">附件系统</span>
          <span class="content">{{ currentProduct.system }}</span>
        </li>
        <li class="item1">
          <span class="title">牵引系统</span>
          <span class="content">{{ currentProduct.traction }}</span>
        </li>
        <li class="item1">
          <span class="title">单步佩戴周期</span>
          <span class="content">{{ currentProduct.period1 }}</span>
        </li>
        <li class="item1">
          <span class="title">Al诊断报告</span>
          <span class="content">{{ currentProduct.al }}</span>
        </li>
        <li class="item1">
          <span class="title">根冠骨系统</span>
          <span class="content">{{ currentProduct.system1 }}</span>
        </li>
        <li class="item1">
          <span class="title">表达率分析</span>
          <span class="content">{{ currentProduct.analyse }}</span>
        </li>
        <li class="item1">
          <span class="title">生产方式</span>
          <span class="content">{{ currentProduct.way }}</span>
        </li>
        <li class="item1">
          <span class="title">数量限制</span>
          <span class="content">{{ currentProduct.quantitative }}</span>
        </li>
        <li class="item1">
          <span class="title">重启</span>
          <span class="content">{{ currentProduct.restart }}</span>
        </li>
        <li class="item1">
          <span class="title">升级费用</span>
          <span class="content">{{ currentProduct.upgrade }}</span>
        </li>
      </ul> -->
    </div>
    <div v-if="isDomainAllowed">
      <ul class="list clearfix listmock">
        <li class="item1" v-for="(item, i) in binglpingpai" :key="i" @change="rdiogaib">
          <el-radio :label="i + 1" v-model="checked1" @change="onChange(i)" size="10" :disabled="disRadio1">
            <img :src="item.logo" style="width: 80%; margin-left: 10px" />
          </el-radio>
          <!-- <div class="text secaitxt">{{ item.info }}</div> -->
          <div style="margin-top: 44px; border-right: 2px solid #d8d8d8; padding: 0 20px 20px 10px">
            <div>
              <div class="titlenew">当前产品</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.product }}
              </div>
            </div>

            <div>
              <div class="titlenew">矫治器</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.appliance }}
              </div>
            </div>
            <div>
              <div class="titlenew">服务期</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">{{ item.period }}</div>
            </div>
            <div>
              <div class="titlenew">适用病例</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }" style="height: 84px">
                {{ item.apply }}
              </div>
            </div>

            <div>
              <div class="titlenew">附件系统</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }" style="height: 63px">
                {{ item.system }}
              </div>
            </div>
            <div>
              <div class="titlenew">牵引系统</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }" style="height: 42px">
                {{ item.traction }}
              </div>
            </div>
            <div>
              <div class="titlenew">单步佩戴周期</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.period1 }}
              </div>
            </div>
            <div>
              <div class="titlenew">Al诊断报告</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">{{ item.al }}</div>
            </div>
            <div>
              <div class="titlenew">根冠骨系统</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.system1 }}
              </div>
            </div>
            <div>
              <div class="titlenew">表达率分析</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.analyse }}
              </div>
            </div>
            <div>
              <div class="titlenew">生产方式</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">{{ item.way }}</div>
            </div>
            <div>
              <div class="titlenew">数量限制</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.quantitative }}
              </div>
            </div>
            <div>
              <div class="titlenew">重启</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.restart }}
              </div>
            </div>
            <div>
              <div class="titlenew">升级费用</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }">
                {{ item.upgrade }}
              </div>
            </div>
            <div>
              <div class="titlenew">禁忌症</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }" style="height: 105px">
                {{ item.contraindication }}
              </div>
            </div>
            <div>
              <div class="titlenew">系列描述</div>
              <div class="contentnew" :style="{ color: checked1 == i + 1 ? '#0075f6' : '#000' }" style="height: 190px">
                {{ item.describe }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Compatible from '../../../util/compatible';
export default {
  data() {
    return {
      checked1: 0,
      binglpingpai: [],
      mock: [
        {
          id: 4140236001,
          product: '畅销系列微矫版',
          trait: '服务一年（可升级）',
          appliance: '单模',
          period: '1年',
          apply: '微正畸，矫正后复发，种植前微调',
          system: '智能附件',
          traction: '精密切割',
          period1: '10-14天',
          al: '无',
          system1: '无',
          analyse: '无',
          way: '一次性全部交付',
          quantitative: '40个',
          restart: '需额外付费',
          upgrade: '补差价升级'
        },
        {
          id: 4140236002,
          product: '畅销系列轻享版',
          trait: '移动5-5牙位病例',
          appliance: '单模',
          period: '2年',
          apply: '移动5-5牙位的简单病例(片切排齐、扩弓排齐、关闭间隙，个别牙反合调整等)',
          system: '智能附件',
          traction: '精密切割,升级正丽扣+298元',
          period1: '10-14天',
          al: '无',
          system1: '无',
          analyse: '无',
          way: '分次交付',
          quantitative: '100个',
          restart: '服务周期内免费',
          upgrade: '补差价升级'
        },
        {
          id: 4140236003,
          product: '畅销系列标准版',
          trait: '5年内免费重启',
          appliance: '单模',
          period: '5年',
          apply: '恒牙列病例',
          system: '智能附件',
          traction: '精密切割,升级正丽扣+298元',
          period1: '10-14天',
          al: '无',
          system1: '无',
          analyse: '无',
          way: '分次交付',
          quantitative: '不限',
          restart: '服务周期内免费',
          upgrade: '不适用'
        },

        {
          id: 4140236005,
          product: '明星系列明星版',
          trait: '双膜矫治',
          appliance: 'A膜：软膜B膜：硬膜',
          period: '5年',
          apply: '大部分病例',
          system: '智能附件、全系列优化系统',
          traction: '升级牵引系统-正丽扣',
          period1: '10天(3+7组合佩戴)',
          al: 'AI测量分析报告',
          system1: '根冠骨系统',
          analyse: '过程监控分析报告',
          way: '分次交付',
          quantitative: '不限',
          restart: '服务周期内免费',
          upgrade: '不适用'
        },
        {
          id: 4140236004,
          product: '明星系列童星版',
          trait: '多层复合膜片',
          appliance: '单模',
          period: '5年',
          apply: '混合牙列早中期病例',
          system: '智能附件、全系列优化系统、咬合重建系统',
          traction: '升级牵引系统-正丽扣',
          period1: '7-10天',
          al: 'AI测量分析报告',
          system1: '无',
          analyse: '过程监控分析报告',
          way: '分次交付',
          quantitative: '不限',
          restart: '服务周期内免费',
          upgrade: '不适用'
        },
        {
          id: 4140236006,
          product: '特别系列专家版',
          trait: '单膜(多层)',
          appliance: '力学加美学设计理念',
          period: '5年',
          apply: '所有病例',
          system: '智能附件、全系列优化系统',
          traction: '升级牵引系统-正丽扣',
          period1: '7-10天',
          al: 'AI测量分析报告',
          system1: '无',
          analyse: '过程监控分析报告',
          way: '分次交付',
          quantitative: '不限',
          restart: '服务周期内免费',
          upgrade: '不适用'
        },
        {
          id: 4140236007,
          product: '特别系列咬合重建',
          trait: '单膜',
          appliance: '咬合重建功能',
          period: '5年',
          apply: '咬合重建病例',
          system: '智能附件、全系列优化系统、咬合重建系统',
          traction: '升级牵引系统-正丽扣',
          period1: '7-10天',
          al: 'AI测量分析报告',
          system1: '无',
          analyse: '过程监控分析报告',
          way: '分次交付',
          quantitative: '不限',
          restart: '服务周期内免费',
          upgrade: '不适用'
        }
      ],
      currentProduct: {},
      isDomainAllowed: false,
      disRadio: false,
      disRadio1: false
    };
  },
  // props: {
  //   disRodio: {
  //     type: Boolean,
  //     dafault: false
  //   },
  //   disRodio1: {
  //     type: Boolean,
  //     dafault: false
  //   }
  // },
  created() {
    // let spu = sessionStorage.getItem('spu');
    // if (spu == 4140236002) {
    //   this.currentProduct = {
    //     product: '畅销系列轻享版',
    //     trait: '移动5-5牙位病例',
    //     appliance: '单模',
    //     period: '2年',
    //     apply: '移动5-5牙位的简单病例(片切排齐、扩弓排齐、关闭间隙，个别牙反合调整等)',
    //     system: '智能附件',
    //     traction: '精密切割,升级正丽扣+298元',
    //     period1: '10-14天',
    //     al: '无',
    //     system1: '无',
    //     analyse: '无',
    //     way: '分次交付',
    //     quantitative: '100个',
    //     restart: '服务周期内免费',
    //     upgrade: '补差价升级'
    //   };
    // } else if (spu == 4140236001) {
    //   this.currentProduct = {
    //     product: '畅销系列微矫版',
    //     trait: '服务一年（可升级）',
    //     appliance: '单模',
    //     period: '1年',
    //     apply: '微正畸，矫正后复发，种植前微调',
    //     system: '智能附件',
    //     traction: '精密切割',
    //     period1: '10-14天',
    //     al: '无',
    //     system1: '无',
    //     analyse: '无',
    //     way: '一次性全部交付',
    //     quantitative: '40个',
    //     restart: '需额外付费',
    //     upgrade: '补差价升级'
    //   };
    // } else if (spu == 4140236003) {
    //   this.currentProduct = {
    //     product: '畅销系列标准版',
    //     trait: '5年内免费重启',
    //     appliance: '单模',
    //     period: '5年',
    //     apply: '恒牙列病例',
    //     system: '智能附件',
    //     traction: '精密切割,升级正丽扣+298元',
    //     period1: '10-14天',
    //     al: '无',
    //     system1: '无',
    //     analyse: '无',
    //     way: '分次交付',
    //     quantitative: '不限',
    //     restart: '服务周期内免费',
    //     upgrade: '不适用'
    //   };
    // } else if (spu == 4140236004) {
    //   this.currentProduct = {
    //     product: '明星系列童星版',
    //     trait: '多层复合膜片',
    //     appliance: '单模',
    //     period: '5年',
    //     apply: '混合牙列早中期病例',
    //     system: '智能附件、全系列优化系统、咬合重建系统',
    //     traction: '升级牵引系统-正丽扣',
    //     period1: '7-10天',
    //     al: 'AI测量分析报告',
    //     system1: '无',
    //     analyse: '过程监控分析报告',
    //     way: '分次交付',
    //     quantitative: '不限',
    //     restart: '服务周期内免费',
    //     upgrade: '不适用'
    //   };
    // } else if (spu == 4140236005) {
    //   this.currentProduct = {
    //     product: '明星系列明星版',
    //     trait: '双膜矫治',
    //     appliance: 'A膜：软膜B膜：硬膜',
    //     period: '5年',
    //     apply: '大部分病例',
    //     system: '智能附件、全系列优化系统',
    //     traction: '升级牵引系统-正丽扣',
    //     period1: '10天(3+7组合佩戴)',
    //     al: 'AI测量分析报告',
    //     system1: '根冠骨系统',
    //     analyse: '过程监控分析报告',
    //     way: '分次交付',
    //     quantitative: '不限',
    //     restart: '服务周期内免费',
    //     upgrade: '不适用'
    //   };
    // } else if (spu == 4140236006) {
    //   this.currentProduct = {
    //     product: '特别系列专家版',
    //     trait: '单膜(多层)',
    //     appliance: '力学加美学设计理念',
    //     period: '5年',
    //     apply: '所有病例',
    //     system: '智能附件、全系列优化系统',
    //     traction: '升级牵引系统-正丽扣',
    //     period1: '7-10天',
    //     al: 'AI测量分析报告',
    //     system1: '无',
    //     analyse: '过程监控分析报告',
    //     way: '分次交付',
    //     quantitative: '不限',
    //     restart: '服务周期内免费',
    //     upgrade: '不适用'
    //   };
    // } else if (spu == 4140236007) {
    //   this.currentProduct = {
    //     product: '特别系列咬合重建',
    //     trait: '单膜',
    //     appliance: '咬合重建功能',
    //     period: '5年',
    //     apply: '咬合重建病例',
    //     system: '智能附件、全系列优化系统、咬合重建系统',
    //     traction: '升级牵引系统-正丽扣',
    //     period1: '7-10天',
    //     al: 'AI测量分析报告',
    //     system1: '无',
    //     analyse: '过程监控分析报告',
    //     way: '分次交付',
    //     quantitative: '不限',
    //     restart: '服务周期内免费',
    //     upgrade: '不适用'
    //   };
    // }

    const currentDomain = window.location.hostname;
    this.isDomainAllowed = currentDomain.includes('yae920.magicalign');
    // this.isDomainAllowed = currentDomain.includes('172.168.0.140');
    // 180.169.239.242 yae920.magicalign

    // 获取病例品牌列表
    this.$http({
      url: '/caseInfo/caseBrands',
      method: 'POST',
      data: {}
    })
      .then(({ data }) => {
        data = JSON.parse(data.data);
        if (this.Compatible.isvvsmile) {
          data.length = 1;
          data[0].logo = require('@/assets/logo_new.png');
          data[0].info = 'vvsmile';
          console.log('获取病例品牌列表 ', data);
        }
        this.binglpingpai = data;

        if (window.location.href.indexOf('yae920.magicalign.com') != -1) {
        // if (window.location.href.indexOf('http://172.168.0.140') != -1) {
          this.binglpingpai = [
            {
              id: 1,
              logo: require('@/assets/yae920Icon/每舒齐/微矫版.png'),
              info: '畅销系列微矫版',
              shows: true,
              product: '畅销系列微矫版',
              trait: '服务一年（可升级）',
              appliance: '单模',
              period: '1年',
              apply: '微正畸，矫正后复发，种植前微调',
              system: '智能附件',
              traction: '精密切割',
              period1: '10-14天',
              al: '无',
              system1: '无',
              analyse: '无',
              way: '一次性全部交付',
              quantitative: '40个',
              restart: '需额外付费',
              upgrade: '补差价升级',
              describe:
                '微正畸专注于处理小范围的牙齿移动，适合成人微调牙齿间隙或轻微扭转情况，是一种便捷、快速的矫正选择。',
              contraindication: '≥Ⅱ 度深覆额/深覆盖,乳牙列/替牙期病例,骨性病例,严重牙周炎病例,咬合重建病例'
            },
            {
              id: 2,
              logo: require('@/assets/yae920Icon/每舒齐/轻享版.png'),
              info: '畅销系列轻享版',
              shows: true,
              product: '畅销系列轻享版',
              trait: '移动5-5牙位病例',
              appliance: '单模',
              period: '2年',
              apply: '移动5-5牙位的简单病例(片切排齐、扩弓排齐、关闭间隙，个别牙反合调整等)',
              system: '智能附件',
              traction: '精密切割,升级正丽扣+298元',
              period1: '10-14天',
              al: '无',
              system1: '无',
              analyse: '无',
              way: '分次交付',
              quantitative: '100个',
              restart: '服务周期内免费',
              upgrade: '补差价升级',
              describe:
                '精简经济型选择，专为只需要轻微调整牙齿的小问题而设计的，它提供了经济实惠且快速的解决方案，疗程时间短，易于管理。',
              contraindication: '拔前磨牙病例,≥Ⅱ度深覆颌/深覆盖,乳牙列/替牙期病例,骨性病例,严重牙周炎病例,咬合重建病例'
            },
            {
              id: 3,
              logo: require('@/assets/yae920Icon//每舒齐/标准版.png'),
              info: '畅销系列标准版',
              shows: true,
              product: '畅销系列标准版',
              trait: '5年内免费重启',
              appliance: '单模',
              period: '5年',
              apply: '恒牙列病例',
              system: '智能附件',
              traction: '精密切割,升级正丽扣+298元',
              period1: '10-14天',
              al: '无',
              system1: '无',
              analyse: '无',
              way: '分次交付',
              quantitative: '不限',
              restart: '服务周期内免费',
              upgrade: '不适用',
              describe:
                '标准版是旗舰产品，专为解决广泛的牙齿矫正需求而设计，无论是轻微还是复杂的牙齿排列问题，都能提供有效的矫正效果。',
              contraindication: '乳牙列/替牙期病例,骨性病例,严重牙周炎病例,咬合重建病例'
            },

            {
              id: 4,
              logo: require('@/assets/yae920Icon/每舒齐/童星版.png'),
              info: '明星系列童星版',
              shows: true,
              product: '明星系列童星版',
              trait: '多层复合膜片',
              appliance: '单模',
              period: '5年',
              apply: '混合牙列早中期病例',
              system: '智能附件、全系列优化系统、咬合重建系统',
              traction: '升级牵引系统-正丽扣',
              period1: '7-10天',
              al: 'AI测量分析报告',
              system1: '无',
              analyse: '过程监控分析报告',
              way: '分次交付',
              quantitative: '不限',
              restart: '服务周期内免费',
              upgrade: '不适用',
              describe: '专为儿童设计的矫治器，能够在不影响美观的情况下，温和而有效地矫正牙齿排列问题。',
              contraindication: '龋齿严重,存在象限牙齿萌出数量,不足2颗恒磨牙萌出,高度不足4mm'
            },
            {
              id: 5,
              logo: require('@/assets/yae920Icon/每舒齐/明星版.png'),
              info: '明星系列明星版',
              shows: true,
              product: '明星系列明星版',
              trait: '双膜矫治',
              appliance: 'A膜:软膜; B膜:硬膜',
              period: '5年',
              apply: '大部分病例',
              system: '智能附件、全系列优化系统',
              traction: '升级牵引系统-正丽扣',
              period1: '10天(3+7组合佩戴)',
              al: 'AI测量分析报告',
              system1: '根冠骨系统',
              analyse: '过程监控分析报告',
              way: '分次交付',
              quantitative: '不限',
              restart: '服务周期内免费',
              upgrade: '不适用',
              describe:
                '荣耀版采用双膜环治技术与优质材料，提供更快的矫正速度和更高的佩戴舒适度，特别适合追求高端体验和快速矫正效果的用户。',
              contraindication: '骨性病例,严重牙周炎病例,咬合重建病例'
            },
            {
              id: 6,
              logo: require('@/assets/yae920Icon/每舒齐/专家版.png'),
              info: '特别系列专家版',
              shows: true,
              product: '特别系列专家版',
              trait: '单膜(多层)',
              appliance: '力学加美学设计理念',
              period: '5年',
              apply: '所有病例',
              system: '智能附件、全系列优化系统',
              traction: '升级牵引系统-正丽扣',
              period1: '7-10天',
              al: 'AI测量分析报告',
              system1: '无',
              analyse: '过程监控分析报告',
              way: '分次交付',
              quantitative: '不限',
              restart: '服务周期内免费',
              upgrade: '不适用',
              describe:
                '引进正畸专家矫治理念，生物力学与人体美学结合，对隐形矫治赋能，不仅关注牙齿的排列，还注重整体面部美学和功能的协调，为患者提供全面而个性化的治疗方案。',
              contraindication: '严重牙周炎病例'
            },
            {
              id: 7,
              logo: require('@/assets/yae920Icon/每舒齐/咬合重建.png'),
              // logo: require('@/assets/yae920Icon/咬合重建.jpg'),
              info: '特别系列咬合重建',
              shows: true,
              product: '特别系列咬合重建',
              trait: '单膜',
              appliance: '咬合重建功能',
              period: '5年',
              apply: '咬合重建病例',
              system: '智能附件、全系列优化系统、咬合重建系统',
              traction: '升级牵引系统-正丽扣',
              period1: '7-10天',
              al: 'AI测量分析报告',
              system1: '无',
              analyse: '过程监控分析报告',
              way: '分次交付',
              quantitative: '不限',
              restart: '服务周期内免费',
              upgrade: '不适用',
              describe:
                '精确调整牙齿和颌骨的位置，优化咬合关系，改善面部结构和功能，重新定位上下颌，实现牙颌骨关系的优化。',
              contraindication: '严重骨性病例,严重牙周炎病例,需要正颌手术病例'
            }
          ];
          // let spuToIdMap = {
          //   4140236003: 3, // 标准版
          //   4140236004: 4, // 童星版
          //   4140236005: 5, // 明星版
          //   4140236006: 6, // 专家版
          //   4140236007: 7, // 咬合重建
          //   4140236001: 1, // 微矫版
          //   4140236002: 2 // 轻享版
          // };
          this.binglpingpai;
        } else {
          this.binglpingpai = data;
        }

        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    rdiogaib() {},

    onChange(index) {
      //   console.log('当前选中品牌索引:', index); // 调试信息
      //   this.currentProduct = this.mock[index] || {}; // 更新 currentProduct
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-radio__inner {
  border-color: #1175d2;
}

/deep/.list {
  margin: 0 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  box-sizing: border-box;
}

/deep/.item {
  // border: 1px solid ;
  padding: 0;
  margin: 0;
  list-style: none;
  // float: left;
  width: 31%;
  height: 100px;
  text-align: center;
  position: relative;
  // margin-top: 82px;
  // margin-bottom: 77px;
  margin-left: 1%;
  margin-right: 1%;

 .el-radio__input {
    margin-top: -18px;
    // margin-right: 15px;
    // margin-left: -10px;
    border-radius: 50%;
    line-height: 3;

    .el-radio__inner {
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

    /deep/.el-radio__inner::after {
      // width: 6px;
      // height: 6px;
      // left: 7px;
      // top: 2px;
    }
  }}
  /deep/.item1 {
  // border: 1px solid ;
  padding: 0;
  margin: 0;
  list-style: none;
  // float: left;
  width: 31%;
  height: 100px;
  text-align: center;
  position: relative;
  // margin-top: 82px;
  // margin-bottom: 77px;
  margin-left: 1%;
  margin-right: 1%;

 .el-radio__input {
    margin-top: -68px;
    // margin-right: 15px;
    // margin-left: -10px;
    border-radius: 50%;
    line-height: 3;

    .el-radio__inner {
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

    /deep/.el-radio__inner::after {
      // width: 6px;
      // height: 6px;
      // left: 7px;
      // top: 2px;
    }
  }
  
  /deep/.el-radio {
    margin-right: 0px;
  }
  .text {
    position: absolute;
    bottom: 40px;
    left: 21%;
    color: #1175d2;
  }

  .secaitxt {
    color: #ff6b00;
  }
}

.xjbl {
  height: 682px;
  overflow-y: auto;
  // background-color: #Fff;
}

/deep/.el-radio__input.is-checked .el-radio__inner,
.el-radio__input.is-indeterminate .el-radio__inner {
  color: #1175d2;
  background: #1175d2;
}

/deep/.list1 {
  margin: 0 9%;
  width: 100%;
  height: 100%;

  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 20px;
}
.item1 {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 23px;
  // float: left;
  // width: 31%;
  // height: 100px;
  // text-align: center;
  // position: relative;
  // margin-top: 45px;

  // margin-bottom: 77px;
  // margin-left: 6%;
  // margin-right: 1%;
  .title {
    // width: 20%;
    // border: 1px solid red;

    font-size: 20px;
    color: #333;
  }

  .content {
    margin-left: 20px;
    font-size: 20px;
    color: #000;
  }
}
.item1:nth-child(n + 2) {
  margin-top: 0;
}
// .item:nth-child(n + 2) {
//   margin-top: 0;
// }
.titlenew {
  text-align: center;
  font-size: 15px;
  color: #999;
  line-height: 40px;
  font-weight: normal;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
}
.contentnew {
  text-align: center;
  font-size: 16px;
  // color: #000;
  line-height: 20px;
  font-weight: 700;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
}
</style>